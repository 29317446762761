import { useEffect, useMemo, useRef, useState } from 'react';
import { useProductsContext } from '../../hooks/useProductsContext';
import { ProductCardWrapper } from '../ProductCardWrapper/ProductCardWrapper';
import FilterWrapper from '../ProductFilter/FilterWrapper/FilterWrapper';
import FilterHeader from '../ProductFilter/FilterHeader/FilterHeader';
import { Typography, LoginPromptBanner } from 'uibook';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { useMediaLayout } from 'use-media';
import { Breadcrumbs } from '../BreadCrumbs/BreadCrumbs';
import { capitalize } from '@/utils/strings.utils';
import { useCustomerContext } from '@/hooks/useCustomerContext';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';

type ProductsGridProps = {
  dataTestId?: string;
};

export const ProductsGrid = ({ dataTestId }: ProductsGridProps) => {
  const {
    filteredProducts,
    filters: { category },
  } = useProductsContext();

  const isMobileOrTablet = useMediaLayout({ maxWidth: NEW_BREAKPOINTS.smallDesktop });
  const [scrolledPastGrid, setScrolledPastGrid] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { hasLoggedInCustomer, isMobileApp } = useCustomerContext();
  const { consumerTypePrefixPath } = useConsumerTypeContext();

  useEffect(() => {
    let observerRefValue: HTMLDivElement | null = null;

    const observer = new IntersectionObserver(([entry]) => {
      if (!isMobileOrTablet) return;
      setScrolledPastGrid(!entry.isIntersecting);
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
      observerRefValue = containerRef.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [containerRef, isMobileOrTablet]);

  const breadcrumbItems = useMemo(() => {
    const allProducts = {
      name: 'All products',
      url: consumerTypePrefixPath('/products'),
    };

    if (category.length < 1) {
      return [
        {
          ...allProducts,
          disabled: true,
        },
      ];
    }

    let categoryName = category[0] === 'tvs' ? 'TVs' : capitalize(category[0] || '');

    if (category.length > 1) {
      categoryName = 'Products';
    }

    return [
      {
        name: 'All products',
        url: consumerTypePrefixPath('/products'),
      },
      {
        name: categoryName,
        url: consumerTypePrefixPath(`/products?category=${category[0]}`),
        disabled: true,
      },
    ];
  }, [category, consumerTypePrefixPath]);

  return (
    <>
      {!hasLoggedInCustomer && !isMobileApp && (
        <>
          <Breadcrumbs items={breadcrumbItems} />
          <LoginPromptBanner
            dataTestId="login-prompt"
            accountBaseUrl={process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}
            classNames="flex"
          />
        </>
      )}
      <div className="mx-auto w-full max-w-screen-xl">
        <FilterHeader scrolledPastGrid={scrolledPastGrid} />
        <div
          data-testid={dataTestId}
          ref={containerRef}
          className="gap-6 p-6 lg:grid lg:grid-cols-[1fr_2fr] xl:grid-cols-[1fr_3fr]"
        >
          <div className="hidden lg:block">
            <FilterWrapper />
          </div>
          {!filteredProducts.length && (
            <Typography className="text-center">
              No results found. Please update your filters and try again.
            </Typography>
          )}
          <div>
            <div
              className="grid w-full gap-6 md:grid-cols-2 xl:grid-cols-3"
              data-testid="products-grid"
            >
              {filteredProducts?.map(
                (
                  {
                    displayName,
                    variantImageUrl,
                    productVariantConditions,
                    variantSlug,
                    lowestMonthlyCost,
                    variantId,
                    cardDisplaySpecification,
                    make,
                    colours,
                    eligible,
                  },
                  productIndex,
                ) => (
                  <ProductCardWrapper
                    key={productIndex}
                    dataTestId={`product-card-${productIndex}`}
                    displayName={displayName}
                    imageUrl={variantImageUrl}
                    productVariantConditions={productVariantConditions}
                    lowestMonthlyCost={lowestMonthlyCost}
                    variantSlug={variantSlug}
                    cardDisplaySpecification={cardDisplaySpecification}
                    make={make}
                    variantId={variantId}
                    colours={colours}
                    eligible={eligible}
                    isPriorityImage={productIndex === 0}
                  />
                ),
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
