import { formatDisplayPrice } from '@/utils/formatDisplayPrice';
import { useProductsContext } from '@/hooks/useProductsContext';
import { useCustomerContext } from '@/hooks/useCustomerContext';
import { useAppContext } from '@/hooks/useAppContext';
import { Link } from '@/elements/Link/Link';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { Badge, CSS_FONT_WEIGHT, ProductCard } from 'uibook';
import { VariantForPLP } from '@/types/productTypes';
import { getImageProps } from 'next/image';

type ProductCardWrapperProps = {
  dataTestId?: string;
  imageUrl: VariantForPLP['variantImageUrl'];
  displayName: VariantForPLP['displayName'];
  variantCondition?: VariantForPLP['variantCondition'];
  productVariantConditions: VariantForPLP['variantCondition'][];
  lowestMonthlyCost: VariantForPLP['lowestMonthlyCost'];
  variantSlug: VariantForPLP['variantSlug'];
  variantId: VariantForPLP['variantId'];
  cardDisplaySpecification: VariantForPLP['cardDisplaySpecification'];
  make: VariantForPLP['make'];
  colours: VariantForPLP['colours'];
  eligible: VariantForPLP['eligible'];
  isPriorityImage?: boolean;
};

export const ProductCardWrapper = ({
  dataTestId,
  imageUrl,
  displayName,
  productVariantConditions,
  lowestMonthlyCost,
  variantSlug,
  cardDisplaySpecification,
  make,
  colours,
  eligible,
  isPriorityImage,
}: ProductCardWrapperProps) => {
  const displayPrice = formatDisplayPrice(lowestMonthlyCost);
  const { consumerTypePrefixPath, consumerIsBusiness } = useConsumerTypeContext();
  const { shouldDisplayPrices } = useProductsContext();
  const { eligibleForAddTech } = useCustomerContext();
  const { setModalOpen } = useAppContext();

  const content = (
    <ProductCard
      productVariantConditions={productVariantConditions}
      make={make}
      displayName={displayName}
      displayPrice={displayPrice}
      cardDisplaySubtitle={cardDisplaySpecification}
      imageUrl={imageUrl}
      colours={colours}
      shouldDisplayPrices={shouldDisplayPrices}
      variantSlug={variantSlug}
      isPriorityImage={isPriorityImage}
      priceSuffix={consumerIsBusiness ? 'excl. VAT' : undefined}
      getImageProps={getImageProps}
    />
  );

  if (eligibleForAddTech) {
    if (eligible) {
      return (
        <Link href={consumerTypePrefixPath(`/products/${variantSlug}`)} data-testid={dataTestId}>
          <Badge
            type="wrapped"
            fontWeight={CSS_FONT_WEIGHT.vars.medium}
            color="blue"
            fontSize={14}
            textTransform="uppercase"
            dataTestId={`${dataTestId}-eligible`}
            icon
          >
            Pre-approved
          </Badge>
          {content}
        </Link>
      );
    }

    return (
      <button
        className="cursor-not-allowed"
        onClick={(event) => {
          event.preventDefault();

          setModalOpen('notApprovedDevice');
        }}
        data-testid={`${dataTestId}-ineligible`}
      >
        {content}
      </button>
    );
  }

  return (
    <Link href={consumerTypePrefixPath(`/products/${variantSlug}`)} data-testid={dataTestId}>
      {content}
    </Link>
  );
};
